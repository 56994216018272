import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/articles-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Vim Commands`}</h1>
    <p>{`These are notes on vim from Lesson 3 of `}<a parentName="p" {...{
        "href": "https://www.youtube.com/channel/UCuXy5tCgEninup9cGplbiFw"
      }}>{`Missing Semester`}</a>{`, this lesson contains a lot of useful information about Vim. `}</p>
    <p>{`Since I've written a quick `}<a parentName="p" {...{
        "href": "https://fabiorosado.dev/blog/introduction-to-vim"
      }}>{`introduction to vim`}</a>{`, the notes on this post are just something to complement that article. I will probably update that article with these notes in the future.`}</p>
    <div className="flex justify-center mt-12">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/a6Q8Na575qc" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    </div>
    <p>{`The one thing that I loved learning in this lesson was how to create new windows and tabs. The lesson didn't mention how to cycle through them, so I did a quick search to figure out how to do it.`}</p>
    <h2>{`Normal mode:`}</h2>
    <ul>
      <li parentName="ul">{`click `}<inlineCode parentName="li">{`X`}</inlineCode>{` to remove a letter`}</li>
      <li parentName="ul">{`use `}<inlineCode parentName="li">{`:qa`}</inlineCode>{` to close all windows`}</li>
    </ul>
    <p>{`Vim windows can have tabs.`}</p>
    <ul>
      <li parentName="ul">{`use `}<inlineCode parentName="li">{`:sp`}</inlineCode>{` to create a new windows`}
        <ul parentName="li">
          <li parentName="ul">{`Cycle between tabs with `}<inlineCode parentName="li">{`ctrl+w`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">{`use `}<inlineCode parentName="li">{`:tabnew`}</inlineCode>{` to open a new tab that can contain a window or more`}
        <ul parentName="li">
          <li parentName="ul">{`Move between tabs with `}<inlineCode parentName="li">{`ctrl+PgUp`}</inlineCode>{` or `}<inlineCode parentName="li">{`ctrl+PgDn`}</inlineCode></li>
        </ul>
      </li>
    </ul>
    <h2>{`Moving around`}</h2>
    <ul>
      <li parentName="ul">{`use `}<inlineCode parentName="li">{`e`}</inlineCode>{` to move to the end of the word`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`0`}</inlineCode>{`  moves the cursor to the beginning of the line`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`$`}</inlineCode>{` moves the cursor to the end of the line`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`^`}</inlineCode>{`  moves to the first non-empty character of a line`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ctrl+u`}</inlineCode>{` scrolls up`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ctrl+d`}</inlineCode>{` scrolls down`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`G`}</inlineCode>{` moves to the end of the file`}
        <ul parentName="li">
          <li parentName="ul">{`so does `}<inlineCode parentName="li">{`:$`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`gg`}</inlineCode>{` moves to the beginning of the file`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`L`}</inlineCode>{` moves to the lowest line in the screen`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`M`}</inlineCode>{` moves to the middle line in the screen`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`H`}</inlineCode>{` moves to the highest line in the screen`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`f<character>`}</inlineCode>{` moves the cursor to the first character - f stands for `}<strong parentName="li">{`find`}</strong></li>
      <li parentName="ul"><inlineCode parentName="li">{`t<character>`}</inlineCode>{` move cursor to character`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`/<word>`}</inlineCode>{` search word`}</li>
    </ul>
    <h2>{`Editing commands`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`u`}</inlineCode>{` undo  changes`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ctrl+r`}</inlineCode>{` redo changes`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`o`}</inlineCode>{` opens a new line under the cursor`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`O`}</inlineCode>{` opens a new line on top of the cursor`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`c`}</inlineCode>{` change mode - used similarly to `}<inlineCode parentName="li">{`d`}</inlineCode>{` but puts you in insert mode`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`ci'`}</inlineCode>{` change word inside '`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`x`}</inlineCode>{` delete a character`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`r`}</inlineCode>{` replace character`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`y`}</inlineCode>{` copy`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`yy`}</inlineCode>{` copy the whole line`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`pw`}</inlineCode>{` copy word `}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`p`}</inlineCode>{` paste`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`~`}</inlineCode>{` change the case of the selected text`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`a`}</inlineCode>{` append word`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`.`}</inlineCode>{` repeat the previous command`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      